import { SUPPORT_LOCALES, loadLocaleMessages, setI18nLanguage } from '@/i18n'
import { createRouter, createWebHistory } from 'vue-router'

function load(component: string) {
  return () => import(`@/views/${component}.vue`)
}

export function setupRouter(i18n: any) {
  const locale =
    i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

  const routes = [
    {
      path: '/:locale',
      name: 'HomeScreen',
      component: load('HomeScreen'),
    },
    {
      path: '/:locale/:storeId',
      name: 'TvScreen',
      component: load('TvScreen'),
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: '/en'
    }
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes
  })
  
  // navigation guards
  router.beforeEach(async (to, from, next) => {
    const paramsLocale = to.params.locale as string

    // use locale if paramsLocale is not in SUPPORT_LOCALES
    if (!SUPPORT_LOCALES.includes(paramsLocale)) {
      return next(`/${locale}`)
    }

    // load locale messages
    if (!i18n.global.availableLocales.includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale)
    }

    // set i18n language
    setI18nLanguage(i18n, paramsLocale)

    return next()
  })

  return router
}
