import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createApp } from 'vue'
import { setupRouter } from '@/router'
import { i18n } from "@/i18n"
import { createPinia } from 'pinia'
import App from '@/App.vue'

const pinia = createPinia()
const router = setupRouter(i18n)

createApp(App)
  .use(pinia)
  .use(i18n)
  .use(router)
  .mount('#app')
